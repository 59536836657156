 <template lang="pug">
  div

    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Анализ ниш в категориях
            CButton(@click="exportTo()", color="info" size="sm" class="m-2") Выгрузить таблицу в Excel

        CListGroup
          CListGroupItem(color="secondary")
            b SPS
            span - Процент продавцов в категории, имеющих продажи
          CListGroupItem(color="secondary")
            b SPB
            span - Процент брендов в категории, имеющих продажи
          CListGroupItem(color="secondary")
            b SPS
            span - Процент продавцов в категории, имеющих продажи
          CListGroupItem(color="secondary")
            b SPP
            span - Процент товаров в категории, имеющих продажи
          CListGroupItem(color="secondary")
            b TStC
            span - Ср. количество продаж, приходящееся на одного продавца
          CListGroupItem(color="secondary")
            b TStS
            span - Ср. количество продаж, приходящееся на один товар
          CListGroupItem(color="secondary")
            b GI
            span - Общий глобальный индекс по десятибальной шкале. Суммарный учет SPS, SPP, TStC и TStS
          CListGroupItem(color="secondary")
            b LI
            span - Лучший локальный индекс по десятибальной шкале, относительно всех категорий. Учитывет SPS, SPP, TStC и TStS сегментов внутри категории

        vuecrud(host="http://wild", ref="maintable", selectedtable = "categories", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:row="binds")
            tr
              td(@click="gotoItem(binds.row)")
                div
                  img(:src="'/img/services/' + binds.row.service + '.ico'")
                  span(style="color: blue; cursor: pointer") &nbsp; {{binds.row.name}}
                div {{binds.row.path}}
              td {{format(binds.row._sales)}}
              td {{format(binds.row._oborot)}}
              td {{format(binds.row._sellers)}}
              td {{format(binds.row._brands)}}
              td {{format(binds.row._goods)}}
              td {{format(binds.row._avgPrice)}}
              //td {{binds.row._sellersNN}}
              //td {{binds.row._brandsNN}}
              //td {{binds.row._goodsNN}}
              td {{format(binds.row._SPS)}}
              td {{format(binds.row._SPB)}}
              td {{format(binds.row._SPP)}}
              td {{format(binds.row._TSTC)}}
              td {{format(binds.row._TSTS)}}
              td {{formatFloat(binds.row._GI)}}
              td {{formatFloat(binds.row._LI)}}

    CModal(title="Анализ цен в категории", color="success", :centered="true", :show.sync="isModalVisible", size="xl")
      AnalyticCategory(ref="thisCat")

</template>

<script>

import AnalyticCategory from "@/views/App/AnalyticCategory";
import BarChart from "@/components/BarChart";

export default {
  name: 'AnalyticPrices',
  components: {BarChart, AnalyticCategory},
  data: function () {
    let that = this;
    return {

      item: [],

      show: true,
      isCollapsed: true,

      isModalVisible: false,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
          categories: that.$route.params.id
        },
        filterRowMargin: 8
      }

    }
  },
  mounted() {

    let that = this;


  },
  methods: {

    columnsupdated: function (cols) {

      // Изменить колонку ID
      cols.delete('Id');

      // Удалить колонку name при выводе
      cols.set('name', {label: "Название", hasFilter: true, });
      cols.delete('service', {isLoadToTable: true});
      cols.delete('sourceID', {isLoadToTable: false});
      cols.delete('path', {label: "Путь"});
      cols.delete('sharedKey', {isLoadToTable: false});
      cols.delete('query', {isLoadToTable: false});
      cols.delete('totalGoods', {isLoadToTable: false});
      cols.delete('lastParsedTimeMS', {isLoadToTable: false});
      cols.delete('cache', {isLoadToTable: false});
      cols.delete('startScrapingTime', {isLoadToTable: false});
      cols.delete('endScrapingTime', {isLoadToTable: false});

      cols.set('_sales', {label: "Продаж", hasFilter: true, filterRow: 1, type: 'number'});
      cols.set('_sellers', {label: "Продавцов", hasFilter: true, filterRow: 1, type: 'number'});
      cols.set('_brands', {label: "Брендов", hasFilter: true, filterRow: 1, type: 'number'});
      cols.set('_oborot', {label: "Выручка", hasFilter: true, filterRow: 1, type: 'number'});
      cols.set('_goods', {label: "Товаров", hasFilter: true, filterRow: 1, type: 'number'});
      cols.set('_avgPrice', {label: "Ср. чек", hasFilter: true, filterRow: 1, type: 'number'});

      cols.delete('_sellersNN', {label: "Продавцов NN", hasFilter: false, type: 'number', filterRow: 2});
      cols.delete('_brandsNN', {label: "Брендов NN", hasFilter: false, type: 'number', filterRow: 2});
      cols.delete('_goodsNN', {label: "Товаров NN", hasFilter: false, type: 'number', filterRow: 2});
      cols.set('_SPS', {label: "SPS", title: 'тут описание', hasFilter: true, type: 'number', filterRow: 2});
      cols.set('_SPB', {label: "SPB", hasFilter: true, type: 'number', filterRow: 2});
      cols.set('_SPP', {label: "SPP", hasFilter: true, type: 'number', filterRow: 2});
      cols.set('_TSTC', {label: "TSTC", hasFilter: true, type: 'number', filterRow: 3});
      cols.set('_TSTS', {label: "TSTS", hasFilter: true, type: 'number', filterRow: 3});
      cols.set('_GI', {label: "GI", hasFilter: true, type: 'number', filterRow: 3});
      cols.set('_LI', {label: "LI", hasFilter: true, type: 'number', filterRow: 3});


    },


    gotoItem(row) {
      //debugger;
      //this.$router.push({name: 'AnalyticCategory', params:{id: row.Id}});
      let that = this;
      this.$route.params.id = row.Id;
      this.$refs.thisCat.update().then(res=> {this.isModalVisible = true;});
    },

    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    },

    formatFloat(number) {
      return (new Intl.NumberFormat('ru-RU').format(parseFloat(parseFloat(number).toFixed(1)))).toString();
    },

    exportTo() {
      this.$refs.maintable.export('xlsx');
    }

  }
}
</script>

<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            div
              h4(class="card-title mb-0") {{item.name}}
            div
              img(:src="'/img/services/' + item.service + '.ico'")
              span(class="")  {{item.path}}

    CCardBody
      //CCardHeader Общая статистика
      CRow(style="margin-top: 15px; margin-left: auto")
        CCol(md="12")
          CDataTable(:items="info_items", :fields="info_itemFields", :sorter="true", hover="hover", striped="striped", border="border", fixed="fixed")

    CCardBody
      // Верхняя стата
      CRow(style="margin-top: 15px; margin-left: auto")
        CCol(md="12")
          CDataTable(:items="items", :fields="itemFields", :sorter="true", hover="hover", striped="striped", border="border", fixed="fixed")
            template(#goods="{item}")
              td
                span {{item.goods}}


</template>

<script>

export default {
  name: 'AnalyticCategory',
  data: function () {
    let that = this;
    return {

      item: {
        id: that.$route.params.id,
        orders: 0,
        price: 0,
        quantity: 0,
        name: "",
        service: 0
      },

      items: [],
      itemFields: [
        {key: "price", label: "Диапазон цен, руб", sorter: true},
        {key: "sellers", label: "Продавцы", sorter: true},
        {key: "brands", label: "Бренды", sorter: true},
        {key: "goods", label: "Товары", sorter: true},
        {key: "sales", label: "Продажи", sorter: true},
        {key: "turnover", label: "Оборот", sorter: true},
        {key: "SPS", label: "SPS", sorter: true},
        {key: "SPP", label: "SPP", sorter: true},
        {key: "TSTC", label: "TSTC", sorter: true},
        {key: "TSTS", label: "TSTS", sorter: true},
        {key: "LI", label: "LI", sorter: true},
      ],

      info_items: [],
      info_itemFields: [
        {key: "name", label: "", sorter: false},
        {key: "price", label: "Цена (руб)", sorter: false},
        {key: "sellers", label: "Продавцы", sorter: true},
        {key: "brands", label: "Бренды", sorter: true},
        {key: "goods", label: "Товары", sorter: true},
        {key: "sales", label: "Продажи", sorter: true},
        {key: "turnover", label: "Оборот", sorter: true},
      ],

      tInfo: [],

      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
          categories: that.$route.params.id
        }
      }

    }
  },
  mounted() {

    this.update();

  },
  methods: {

    setPeriod: function (period, periodFrom, periodTo) {

      this.period.button = period;

      if (period !== 0) {
        let d = (new Date())
        d.setDate(d.getDate() - period);
        this.period.from = d.toISOString().slice(0, 19).replace('T', ' ');
        this.period.to = (new Date()).toISOString().slice(0, 19).replace('T', ' ');
      } else {
        //TODO: периодфром и ту
        this.period.from = 0;
        this.period.to = 0;
      }

      // Делаем запрос с новыми данными
      this.update();

    },

    update: function () {

      let that = this;

      // сперва грузим сам товар
      return window.REST.get('categories', {Id: this.$route.params.id}).then(res => {
        let data = [];

        if(res.data[0].cache) {
          data = JSON.parse(res.data[0].cache).data;
        }

        data = data.map(r=>{Object.assign(r, r.items, r.indexes); r.price=r.start_price + " - "  + r.end_price; r.id=that.$route.params.id; return r;});

        that.$set(that, 'items', data);
        that.$set(that, 'item', res.data[0]);

        // формируем верхнюю инфу
        let i = JSON.parse(res.data[0].cache).info;

        let info = [];

        info.push({
          "price": "",
          "name": "Всего",
          "sellers": i['sellers'],
          "brands": i['brands'],
          "goods": i['goods'],
          "sales": i['sales'],
          "turnover": i['turnover']
        });

        info.push({
          "price": "",
          "name": "Имеют продажи",
          "sellers": i['sellers_nn'],
          "brands": i['brands_nn'],
          "goods": i['goods_nn'],
          "sales": 0,
          "turnover": 0
        });

        info.push({
          "price": (i['turnover']/i['sales']).toFixed(0),
          "name": "Средняя",
          "sellers": "",
          "brands": "",
          "goods": "",
          "sales": "",
          "turnover": ""
        })

        info.push({
          "price": i['median'],
          "name": "Медиана",
          "sellers": "",
          "brands": "",
          "goods": "",
          "sales": "",
          "turnover": ""
        })

        info.push({
          "price": i['min_price'],
          "name": "Мин",
          "sellers": "",
          "brands": "",
          "goods": "",
          "sales": "",
          "turnover": ""
        })

        info.push({
          "price": i['max_price'],
          "name": "Макс",
          "sellers": "",
          "brands": "",
          "goods": "",
          "sales": "",
          "turnover": ""
        })

        that.$set(that, 'info_items', info);
        that.$set(that, 'tInfo', i);




        //that.$set(this.$route.meta,'label', that.item.name);
        //that.$store.commit('routeThis', this);

      })

    },

    columnsupdated: function (cols) {

      cols.set('brand', {label: "Бренд", hasFilter: true,});
      cols.set('name', {label: "Название", hasFilter: true,});
      cols.set('rating', {label: "Позиция", hasFilter: true,});
      cols.set('color', {label: "Цвет", hasFilter: false,});
      cols.set('orders', {label: "Продаж", hasFilter: true,});
      cols.set('price', {label: "Цена", hasFilter: true,});
      cols.set('quantity', {label: "Остатки", hasFilter: true,});
      cols.set('reviews', {label: "Отзывы", hasFilter: true,});
      cols.set('stars', {label: "Оценка", hasFilter: true,});
      cols.set('sellername', {label: "Продавец", hasFilter: true,});
      cols.set('_totalsumm', {label: "Оборот", hasFilter: true,});


      // Ненужные колонки
      cols.delete('Id');
      cols.delete('service');
      cols.delete('sourceID');
      cols.delete('categories');
      cols.delete('brandID');
      cols.delete('priceFull');
      cols.delete('discount');
      cols.delete('firstreview');
      cols.delete('sellerid');
      cols.delete('imglink');
      cols.delete('changes');
      cols.delete('_avgPos');
      cols.delete('_avgPrice');
      cols.delete('_stockDays');
      cols.delete('_saleDays');
      cols.delete('_saleSumm');
      cols.delete('_countedDays');

    },
    gotoItem(row) {
      this.$router.push({name: 'Good', params: {id: row.id}});
    }

  }
}
</script>

<style lang="scss" scoped>
.good {
  color: blue;
  cursor: pointer;
}
.good:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>